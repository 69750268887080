import { Controller } from 'stimulus'
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import 'nouislider/distribute/nouislider.css';
import '../stylesheets/nouislider-custom.css';

export default class extends Controller {
  static targets = [
    'sidePanel',
    'filter',
    'slider',
    'manage',
    'confirmation',
    'modal',
    'cart'
  ]

  connect() {
    this.sliderTargets.forEach((slider) => {
      const start = parseInt(slider.dataset.start) || 0
      const end = parseInt(slider.dataset.end) || 1000

      const min = parseInt(slider.dataset.min) || 0
      const max = parseInt(slider.dataset.max) || 1000

      noUiSlider.create(slider, {
        start: [start, end],
        connect: true,
        format: wNumb({
          decimals: 0
        }),
        pips: {
          mode: 'range',
          density: 100
        },
        range: {
          'min': min,
          'max': max
        }
      })

      slider.noUiSlider.on('start', function() {
        this.updateOptions({ tooltips: true })
      })

      slider.noUiSlider.on('end', function() {
        this.updateOptions({ tooltips: false })
      })

      slider.noUiSlider.on('set', function(values) {
        this.target.children[0].value = values[0]
        this.target.children[1].value = values[1]
      })
    })
  }

  openFilter(event) {
    event.preventDefault()
    this.sidePanelTarget.classList.remove('hidden')
    this.filterTargets.forEach((filter) => {
      filter.classList.remove('hidden')
    })
    this.manageTarget.classList.add('hidden')
    // this.sidePanelTarget.classList.add('animated', 'slideInRight')
  }

  openManage(event) {
    event.preventDefault()
    this.sidePanelTarget.classList.remove('hidden')
    this.filterTargets.forEach((filter) => {
      filter.classList.add('hidden')
    })
    this.manageTarget.classList.remove('hidden')
  }

  closeFilter() {
    this.sidePanelTarget.classList.add('hidden')
  }

  buySuccess() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Set overflow hidden to prevent scrolling of background
    // This must happen after the scrollbar calculation
    document.body.style.overflow = 'hidden'

    // Show the modal
    this.confirmationTarget.classList.remove('hidden')

    // Animate the cart
    // TODO Update the cart count before animate
    // this.cartTarget.classList.add('animated', 'zoomIn')
  }

  modalAction() {
    this.modalTarget.classList.add('hidden')
  }
}
