import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.load()
  }

  load() {
    fetch(this.data.get('url'))
      .then((response) => {
        if (!response.ok) {
          console.warn('Fetching content failed.')
          return '';
        }
        return response.text()
      })
      .then(html => {
        this.element.innerHTML = html
      })
      .catch((error) => {
        console.warn('Fetching content failed.')
      });
  }
}
