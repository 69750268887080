import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'nav', 'navLinks' ]

  toggle() {
    this.navTarget.classList.toggle('bg-white')
    this.navTarget.classList.toggle('bg-indigo-800')
    this.navLinksTarget.classList.toggle('hidden')
  }
}
