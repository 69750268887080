import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  show(event) {
    event.preventDefault()
    this.containerTarget.classList.remove('hidden')
  }

  close(event) {
    event.preventDefault()
    this.containerTarget.classList.add('hidden')
  }
}
