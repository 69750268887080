import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'addons',
    'buyBtn',
    'confirmation',
    'paypalBtn',
    'productForm',
    'total'
  ]

  connect() {
    this.paypalBtnId = `#${this.paypalBtnTarget.id}`

    this.addons = 0
    this.keywordRankingId = null
    this.keywordsAddonPrice = 0
    this.searchAddonPrice = 0
    this.searchTrafficId = null
    this.total = 0

    this.productId = this.data.get('id')
    this.productPrice = parseFloat(this.data.get('price'))

    this.updateTotal()
  }

  buy(event) {
    event.preventDefault()

    const element = document.head.querySelector('meta[name="csrf-token"]')
    const authenticity_token = element.getAttribute('content')

    fetch('/buy-product', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authenticity_token,
        product: {
          uid: this.productId,
          price: this.productPrice,
          amount: this.total,
          addons: {
            search_traffic_id: this.searchTrafficId,
            keyword_ranking_id: this.keywordRankingId,
          }
        }
      })
    })
    .then(response => response.json())
    .then(response => {
      // iziToast.success({ title: 'Success:', message: 'Taking you to cart.' })
      // setTimeout(function() {
      //   window.location = response.path
      // }, 3000);
      // this.showPaymentBtn(response)

      // Add right padding to the body so the page doesn't shift
      // when we disable scrolling
      let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
      document.body.style.paddingRight = `${scrollbarWidth}px`

      // Set overflow hidden to prevent scrolling of background
      // This must happen after the scrollbar calculation
      document.body.style.overflow = 'hidden'

      // Show the modal
      this.confirmationTarget.classList.remove('hidden')

      // Animate the cart
      // TODO Update the cart count before animate
      // this.cartTarget.classList.add('animated', 'zoomIn')
    })
    .catch(error => {
      iziToast.error({ title: 'Failed:', message: 'Add to cart failed.' })
      console.error(error)
    })
  }

  showPaymentBtn({ amount, order_id }) {
    this.productFormTarget.classList.add('pointer-events-none')
    this.buyBtnTarget.classList.add('hidden')
    this.paypalBtnTarget.classList.remove('hidden')

    const paypalBtnId = this.paypalBtnId
    const confirmOrder = this.confirmOrder

    if (!(amount > 0)) {
      return
    }

    paypal.Buttons({
      style: {
        color:  'blue',
        shape:  'pill',
        label:  'pay',
        height: 40
      },

      onError: function (err, a, b) {
        console.log(err)
        iziToast.error({ title: 'Failed:', message: `Payment failed.` })
      },

      // Set up the transaction
      createOrder: function(data, actions) {
        return actions.order.create({
          purchase_units: [{
            amount: {
                value: amount
            },
            reference_id: order_id,
            invoice_id: order_id
          }]
        });
      },

      // Finalize the transaction
      onApprove: function(data, actions) {
        console.log('data:', data)
        console.log('Order ID:', data.orderID)
        console.log('Payer ID:', data.payerID)
        return actions.order.capture().then(function(details) {
          console.log(details)
          console.log(details.status == 'COMPLETED')
          console.log(details.purchase_units[0].amount.value)

          confirmOrder(order_id, details)
        });
      }
    }).render(paypalBtnId)
  }

  confirmOrder(order_id, details) {
    const element = document.head.querySelector('meta[name="csrf-token"]')
    const authenticity_token = element.getAttribute('content')

    fetch('/payment', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authenticity_token,
        order_id,
        details
      })
    })
    .then(response => response.json())
    .then(response => {
      iziToast.success({ title: 'Success:', message: 'Payment successful.' })
      window.location = response.path
    })
    .catch(error => {
      iziToast.error({ title: 'Failed:', message: 'Payment failed.' })
      console.error('Error:', error)
    })
  }

  buySuccess() {
    console.log('buySuccess')
  }

  updateTotal() {
    this.addons = this.searchAddonPrice + this.keywordsAddonPrice

    this.addonsTarget.textContent = `$${this.addons}`

    this.total = this.productPrice + this.addons
    this.totalTarget.textContent = `$${this.total}`
  }

  searchAddonChange(event) {
    const select = event.target
    const selected = select.options[select.selectedIndex]

    if (selected.value) {
      this.searchTrafficId = selected.dataset.searchTrafficId
      this.searchAddonPrice = parseFloat(selected.dataset.price)
    } else {
      this.searchTrafficId = null
      this.searchAddonPrice = 0
    }

    this.updateTotal()
  }

  keywordsAddonChange(event) {
    const select = event.target
    const selected = select.options[select.selectedIndex]

    if (selected.value) {
      this.keywordRankingId = selected.dataset.keywordRankingId
      this.keywordsAddonPrice = parseFloat(selected.dataset.price)
    } else {
      this.keywordRankingId = null
      this.keywordsAddonPrice = 0
    }

    this.updateTotal()
  }
}
