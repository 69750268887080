import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    const slider = this.element
    tns({
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayHoverPause: true,
      axis: 'vertical',
      container: slider,
      controls: false,
      controlsPosition: 'bottom',
      items: 1,
      navPosition: 'bottom',
      rewind: true,
      slideBy: 1
    })
  }
}
