import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'paymentBtn', 'paypalBtn', 'paymentForm' ]

  connect() {
    this.paypalBtnId = `#${this.paypalBtnTarget.id}`
    this.orderId = parseInt(this.data.get('id'))
    this.orderAmount = parseFloat(this.data.get('amount') || 0)
  }

  checkoutSuccess() {
    Sentry.init({
      dsn: window.SENTRY_DSN,
      integrations: [
        new Integrations.BrowserTracing(),
      ],

      tracesSampleRate: 1.0,
    });

    this.paymentFormTarget.classList.add('pointer-events-none')
    this.paymentBtnTarget.classList.add('hidden')

    const paypalBtnId = this.paypalBtnId
    const orderId = this.orderId
    const orderAmount = this.orderAmount
    const confirmOrder = this.confirmOrder

    if (!(orderAmount > 0)) {
      return
    }

    paypal.Buttons({
      style: {
        color:  'blue',
        shape:  'pill',
        label:  'pay',
        height: 40
      },

      onError: function (err, a, b) {
        iziToast.error({ title: 'Failed:', message: `Payment failed.` })
        console.log(err)
        Sentry.captureException(err);
      },

      // Set up the transaction
      createOrder: function(data, actions) {
        return actions.order.create({
          purchase_units: [{
            amount: {
                value: orderAmount
            },
            reference_id: orderId,
            invoice_id: orderId
          }]
        });
      },

      // Finalize the transaction
      onApprove: function(data, actions) {
        console.log('data:', data)
        console.log('Order ID:', data.orderID)
        console.log('Payer ID:', data.payerID)
        return actions.order.capture().then(function(details) {
          console.log(details)
          console.log(details.status == 'COMPLETED')
          console.log(details.purchase_units[0].amount.value)

          confirmOrder(orderId, details)
        });
      }
    }).render(paypalBtnId)
  }

  confirmOrder(order_id, details) {
    const element = document.head.querySelector('meta[name="csrf-token"]')
    const authenticity_token = element.getAttribute('content')

    fetch('/payment', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authenticity_token,
        order_id,
        details
      })
    })
    .then(response => response.json())
    .then(response => {
      iziToast.success({ title: 'Success:', message: 'Payment successful.' })
      window.location = response.path
    })
    .catch(error => {
      iziToast.error({ title: 'Failed:', message: 'Payment failed.' })
      console.error('Error:', error)
    })
  }
}
